.app {
  display: flex;
  height: 100%;
}

.nav {
  width: 425px;
  min-width: 425px;
}

.main {
  flex-grow: 1;
  overflow: auto;
}

.orgEmptyState {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  color: rgb(175, 175, 175);
  font-size: 1.5rem;
}

.orgEmptyState > img {
  width: 8rem;
  opacity: 0.6;
}