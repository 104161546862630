.feature {
    width: 100%;
    margin-top: 3em;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2em;
}

.details {
    min-width: 40%; /* so the skeleton isn't zero width */
}

.name {
    font-size: 2em;
}

.description {
    margin-top: 0.5em;
    color: var(--text-secondary);
}