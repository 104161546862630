@import "react-loading-skeleton/dist/skeleton.css";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
}

ul {
    margin: 1rem;
}

html, body, #root {
    height: 100%;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
  
h1, h2, h3 {
    font-weight: normal;
}

ul > li {
    line-height: 1.5rem;
    padding: 0.25rem 0;
}

:root {
    --text-secondary: #616161;
    --text-primary-error: #ff5858d4
}

.container {
    padding: 1.5rem;
    width: 80%;
}

.title {
    font-size: 2.5rem;
}

.title + .hint {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    color: var(--text-secondary);
    padding-left: 0.75rem;
    text-overflow: ellipsis;
}

.title + .hint.short-uuid {
    width: 9ch;
}

.subtitle {
    display: block;
    font-size: 1.5rem;
    color: var(--text-secondary);
    text-decoration: none;
}

.error > * {
    color: var(--text-primary-error) !important
}

.chip-list {
    display: flex;
    column-gap: 0.5rem;
    padding: 0.5rem 0;
}

.error-toast {
    flex-direction: column;
    max-width: 650px;
    background-color: #d32f2f;
    padding: 2rem;
    border-radius: 4px;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
}

.error-toast > * {
    color: #fff;
}

.uuid {
    font-family: "Courier New";
}