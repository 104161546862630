.list {
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: 1em;
    overflow-y: scroll;
    padding: 1em;
}

.card {
    background-color: #e8e8e8;
    padding: 1em;
    border-radius: 0.5em;
    cursor: pointer;
}

.cardSelected {
    outline: 2px solid #5377fc;
}

.title {
    font-size: 1.25em;
}

.description {
    color: var(--text-secondary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}