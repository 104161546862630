@import '../global.css';

.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5em;
    color: var(--text-secondary);
    padding: 3rem !important;
}

.emptyStateMsg {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5em;
}