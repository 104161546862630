.container {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f5f7fc;
    padding-top: 6rem;
}

.login {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.login h1 {
    margin: 0 0 2rem 0;
}

.logo {
    width: 6rem;
    margin: 0 0 2rem 1.5rem;
}

.loginPanel {
    border-radius: 5px;
    padding: 3em;
    background-color: #ffffff;
    box-shadow: 0 10px 20px 0 rgb(149 149 149 / 48%)
} 